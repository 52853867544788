import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// assets
import broVideo from '../../../../video/bro-video.mp4';
// styles
import styles from './header.module.css';

interface Props {
  children: React.ReactNode;
  subNavigation: () => React.ReactNode;
}

function Header({ children, subNavigation }: Props) {
  const data = useStaticQuery(graphql`
    query {
      bgImg: file(relativePath: { eq: "bg-img-header-home.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgVideoImg: file(relativePath: { eq: "first-frame-video.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <header>
      <div className={styles.container}>
        <video
          autoPlay
          loop
          playsInline
          className={styles.video}
          muted
          poster={data.bgVideoImg.childImageSharp.fluid.srcWebp}
        >
          <source src={broVideo} type="video/mp4" />
        </video>
        <div className={styles.wave} />

        <Img
          className={styles.background}
          fluid={data.bgImg.childImageSharp.fluid}
          loading="eager"
          fadeIn={false}
          alt=""
        />
        <div className={styles.content}>
          <div className={styles.innerContent}>{children}</div>
        </div>
      </div>
      <div>{!!subNavigation && subNavigation()}</div>
    </header>
  );
}

export default Header;
