import * as React from 'react';
import styles from './header.module.css';
import clsx from 'clsx';
import Wave from 'pages/components/header/wave';

interface Props {
  children: React.ReactNode;
  subNavigation: () => React.ReactNode;
}

function Header({ children, subNavigation }: Props) {
  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
        <div className={clsx('inner', styles.contentContainer)}>
          <div className={styles.content}>{children}</div>
        </div>
        <Wave color="#ffffff" opacity={0.08} />
      </div>
      {!!subNavigation && subNavigation()}
    </header>
  );
}

export default Header;
