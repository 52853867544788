const Routes = require('../../routes').Routes;

export default [
  {
    title: 'AI Development',
    to: Routes.SERVICES_AI,
  },
  {
    title: 'team \n augmentation ',
    to: Routes.SERVICES_TEAM_AUGMENTATION,
  },
  {
    title: 'MVP\ndevelopment',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    title: 'Saas \n development ',
    to: Routes.SERVICES_SAAS_DEVELOPMENT,
  },
  {
    title: 'Dedicated\nteam',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    title: 'Devops \n services',
    to: Routes.SERVICES_DEVOPS,
  },
  {
    title: 'software \n testing',
    to: Routes.SERVICES_QA,
  },
  {
    title: 'Product\ndevelopment ',
    to: Routes.SERVICES_PRODUCT_DEVELOPMENT,
  },
  {
    title: 'Digital\ntransformation',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
  {
    title: 'Mobile app\ndevelopment',
    to: Routes.SERVICES_MOBILE_APP_DEVELOPMENT,
  },
  {
    title: 'Web app\ndevelopment',
    to: Routes.SERVICES_WEB_APP_DEVELOPMENT,
  },
];
