import React from 'react';
import clsx from 'clsx';
import loadable from '@loadable/component';
import { useStaticQuery, graphql } from 'gatsby';
//styles
import styles from '../services.module.css';
//components
import Bullets from 'components/bullets';
import Booking from 'components/feedback';
import Layout from '../components/layout';
import Recognition from '../../recognition';
import Blockquote from '../components/blockquote';
import mobileAppDevBulletsList from '../components/mobile-app-dev.json';
import { MyCurrency, KoroBox, Gokada, Ifit, Anuel } from 'components/carousel';
import Technology, { TechnologyGroup } from '../../technology';
import Tabs from '../../tabs';
//data
import { meta } from '../../../metaData';
import FAQ from 'components/faq';
// hooks
import { useFaqData } from 'hooks/useFaqData';

const LIST = [
  {
    title: 'INTRO CALL',
  },
  {
    title: 'DISCOVERY AND ANALYSIS',
  },
  {
    title: 'PROTOTYPING AND DESIGN',
  },
  {
    title: 'DEVELOPMENT AND QA',
  },
  {
    title: 'DEPLOYMENT AND RELEASE',
  },
  {
    title: 'MAINTENANCE AND SUPPORT',
  },
];

const SwiperSlider = loadable(() => import('components/swiper-slider'));
const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));

interface Props {
  location: Location;
}

const MobileAppDevelopment = ({ location }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      patrickKedziora: file(relativePath: { eq: "kedziora.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kedzoh: file(relativePath: { eq: "kedzoh-logo.svg" }) {
        publicURL
      }
      bukenya: file(relativePath: { eq: "anuel-bukenya.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      myCurrencyLogo: file(relativePath: { eq: "my-currency-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const faqData = useFaqData('allStrapiService', 'Mobile app development');

  return (
    <Layout
      title="Mobile Application Development"
      subtitle="Bring your products and services closer to their users by implementing a custom mobile application developed with your business objectives in mind. We know how to do this in a fast and cost-efficient way for both iOS and Android, without compromising quality and usability."
      btnText="BUILD YOUR APP"
      metaData={{ main: meta.mobileAppDev, faq: faqData?.list }}
      pathname={location.pathname}
    >
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.title, styles.titleMargin)}>
            Our expertise in cross-platform mobile development
          </h2>
          <p className="subtitle">
            We opt for cross-platform because it’s agile, flexible, and universal. Energy management
            tool, snack service, or ride-sharing app — you name it, we’ve built it.
          </p>
          <Bullets list={mobileAppDevBulletsList} className={styles.bullets} />
        </div>
      </section>
      <SwiperSlider>
        <MyCurrency />
        <KoroBox />
        <Anuel />
        <Gokada />
        <Ifit />
      </SwiperSlider>
      <section className={styles.section}>
        <div className="inner">
          <h2 className={clsx(styles.title, styles.titleMargin)}>Work process</h2>
          <Tabs list={LIST} btnClassName="tabs__btn_30" isArrow>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Here at Brocoders, we work shoulder-to-shoulder with you in an open manner
                  throughout the entire application development life-cycle. To cover all details and
                  ensure the completeness of the requirements elicitation, we start from a first
                  call and meetings with you.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 hour</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      NDA <br />
                      Consultation
                    </p>
                  </li>
                  <li>
                    <h4>Participants</h4>
                    <p>
                      Account Manager <br />
                      Technical advisor <br />
                      Stakeholder
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <TechnologyGroup technology={['skype', 'zoom', 'googleMeet']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Besides professional mobile consulting, we study your company’s business
                  processes, match them to mobile app requirements or features you’d like to add,
                  analyze competitors along with potential risks to end up with a robust development
                  strategy.
                </p>
                <p>
                  Either you are a startup that requires a mobile product, or a business that needs
                  a new feature to be integrated into an existing solution, we are ready to evaluate
                  your idea and provide a piece of expert advice.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Up to 3 days</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Project proposal <br />
                      Contract for prototype deliver
                    </p>
                  </li>
                  <li>
                    <h4>Participants</h4>
                    <p>
                      Account manager <br />
                      Business analyst <br />
                      Stakeholders
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <h3>Low-fidelity prototype</h3>
                <p>
                  Based on gathered data and driven by the iterative development model, we identify
                  the feature flow and prepare the wireframes along with a low-fidelity mobile app
                  prototype.
                </p>
                <h3>Feature-based estimation</h3>
                <p>
                  It’s crucial to evaluate the entire project and provide you with a clear vision of
                  how much time and money the development process might take. That said, we use a
                  feature decomposition to split the functionality into small parts, which can then
                  be analyzed by our technical experts. Our clients have full access to all relevant
                  data, so they can assess further steps, as well as present the budget to
                  investors. Once it’s done, we adjust a toolset and a tech stack for the
                  development stage.
                </p>
                <h3>Design</h3>
                <p>
                  Our UI/UX designers have extensive experience in creating eye-catching and
                  user-friendly mobile apps that proved to be remarkable solutions. We stick to
                  Figma to design the UX/UI elements for our projects. If requested, we provide you
                  with an interactive prototype, so that you can test the interface usability and
                  leave your feedback.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 month</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Low-fidelity prototype in Figma <br />
                      Feature-based estimation <br />
                      Feature-decomposition <br />
                      Graphic project in Figma <br />
                      Contract for development
                    </p>
                  </li>
                  <li>
                    <h4>Participants</h4>
                    <p>
                      Business analyst <br />
                      Designer <br />
                      Project manager <br />
                      Technical advisor <br />
                      Stakeholders
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <Technology list={['figma', 'notion']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  It’s time to establish a development environment, define the application
                  architecture, make a roadmap and choose the right candidates to set up a team that
                  fits your company’s requirements and vision. To strengthen the overall performance
                  and ensure everything is working properly, we carry out functional, integration,
                  and usability tests. We also integrate manual and auto QA testing at this stage.
                </p>
                <p>
                  At the end of each sprint, we provide the stakeholders with a demo to showcase the
                  workflow and the functionality we’ve already made. Hence, all the stakeholders
                  have a clear vision of the working process, so they can leave feedback and make
                  sure we are on the right path.
                </p>
                <h3>Project management</h3>
                <p>
                  We involve experienced project managers to take care of every step and the entire
                  technological process, so you can pay maximum attention to your strategic and
                  business goals. Based on years of practice, we’ve come up with a robust and
                  well-established workflow, which proved to be highly effective and
                  client-oriented.
                </p>
                <h3>Transparent work process</h3>
                <p>
                  To ensure a project’s safety, we apply the latest security and agile-driven
                  practices, as well as provide transparent reports upon your request. Besides, you
                  can come to our office any time to work with our team and look at the whole work
                  process yourself. Moreover, we can strengthen a project by applying extra
                  resources once you request to do so.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Depends on a project</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Environment setup <br />
                      Staging and production servers <br />
                      Intermediate releases <br />
                      Weekly reports
                    </p>
                  </li>
                  <li>
                    <h4>Participants</h4>
                    <p>
                      Business analyst <br />
                      Project manager <br />
                      Development team <br />
                      QA team <br />
                      DevOps <br />
                      Stakeholders
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'gatsby']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  We believe it’s better to double-check everything from scratch, so we go through
                  the pre-launch checklist to ensure the application is fully-functioning and ready
                  for deployment.
                </p>
                <p>Then we submit and upload your product to the AppStore or Google Play.</p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Final release</p>
                  </li>
                  <li>
                    <h4>Participants</h4>
                    <p>
                      Business analyst <br />
                      Project manager <br />
                      Designer <br />
                      DevOps
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <Technology list={['amazon']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Due to the client-oriented approach, our company keeps an eye on your product
                  after the final release and helps you scale it once the total number of end-users
                  starts growing.
                </p>
                <p>
                  At Brocoders, we care deeply about our customers and the solutions we develop, so
                  we provide continuous maintenance and support to our clients, as well as assign a
                  dedicated developer to a project to fix any possible bugs and respond to
                  inquiries.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Fixes and improvements</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account manager <br />
                      Project manager <br />
                      Development team <br />
                      Stakeholders
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </Tabs>
        </div>
      </section>
      <section className="section">
        <ReviewSlider swiperClassName={styles.blockquoteSwiper}>
          <Blockquote
            text={`“I was extremely satisfied and rate them 100%.\n\nCommunication was clear and continuous. We were always informed about progress and issues that inevitably poop up during a complex project.”`}
            img={data.patrickKedziora.childImageSharp.fluid}
            logoImg={data.kedzoh.publicURL}
            isLogo={true}
            blockquoteClassName={styles.blockquote}
            isRating
            withTitle
            author={
              <React.Fragment>
                <strong>Patrick Kedziora </strong> <br />
                CEO,{' '}
                <a
                  href="https://www.f6s.com/company/kedzoh#about"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  Kedzoh
                </a>
              </React.Fragment>
            }
          />
          <Blockquote
            text={`"The platform has received positive feedback. For such an extensive, complicated project, Brocoders has taken scope changes in stride and has effectively implemented change requests. They're organized and communicative, even corresponding during after-hours. They're a flexible and patient partner."`}
            img={data.bukenya.childImageSharp.fluid}
            isRating
            withTitle
            author={
              <React.Fragment>
                <strong>Emmanuel Bukenya</strong> <br />
                CEO, Fine - Renewable Energy Startup
              </React.Fragment>
            }
          />
          <Blockquote
            text="“They have a lot of experience, and we’ve avoided all the common pitfalls for this kind of project.
            Their professionalism, experience, and focus on delivery are excellent.”"
            img={data.myCurrencyLogo.childImageSharp.fluid}
            isRating
            withTitle
            author={
              <React.Fragment>
                <strong>Amin Khadempour </strong> <br />
                CEO &amp; Founder,{' '}
                <a
                  href="https://apps.apple.com/ae/app/mycurrency/id1473777410"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  MyCurrency
                </a>
              </React.Fragment>
            }
          />
        </ReviewSlider>
      </section>
      <section className={clsx('section', styles.sectionMargin)}>
        <div className="inner">
          <h2 className="title">Recognition</h2>
          <Recognition />
        </div>
      </section>
      <section className="inner">
        <FAQ data={faqData} />
      </section>
      <Booking />
    </Layout>
  );
};

export default MobileAppDevelopment;
