import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
//styles
import styles from './layout.module.css';
//components
import Layout, { IMetaData } from 'components/layout';
import Header from './header';
import HeaderWithMovie from './headerWithMovie';
import Footer from 'components/layout/footer';
import SubNavigation from 'components/layout/header/subnav';
import list from './list';
//hooks
import useHeaderContact from 'hooks/useHeaderContact';
// other
import identificators from 'components/googleAnalyticsIdentificators';

interface Props {
  title: string | JSX.Element;
  metaData: IMetaData;
  subtitle: string | JSX.Element;
  children: React.ReactNode;
  pathname: string;
  btnText?: string;
  isWithSubNavigation?: boolean;
  logoImg?: string;
  stylesImg?: string;
  linkTo?: string;
  withMovie?: boolean;
  getInTouchTextColor?: string;
  titleStyles?: string;
  subtitleStyles?: string;
  customBtns?: React.ReactNode;
}

function Main({
  title,
  metaData,
  subtitle,
  children,
  pathname,
  btnText = 'Contact us',
  isWithSubNavigation = true,
  getInTouchTextColor = 'var(--purple-color)',
  stylesImg,
  logoImg,
  withMovie,
  linkTo,
  titleStyles,
  subtitleStyles,
  customBtns,
}: Props) {
  const ServiceHeader = withMovie ? HeaderWithMovie : Header;
  const navigation = () => {
    if (isWithSubNavigation) {
      return (
        <SubNavigation list={list} pathname={pathname} className={styles.subnav} isComposite />
      );
    }
  };
  const contact = useHeaderContact();

  return (
    <Layout title={title} navBtnTextColor={getInTouchTextColor} metaData={metaData}>
      <ServiceHeader subNavigation={navigation}>
        <h1 className={clsx('header-title', styles.title, titleStyles)}>{title}</h1>
        <p className={clsx('header-text', subtitleStyles)}>{subtitle}</p>
        {logoImg && <img src={logoImg} className={stylesImg} alt="" />}

        {customBtns ? (
          customBtns
        ) : (
          <div className={btnText.length > 15 ? styles.btnBox : styles.btnGroup}>
            <Link
              id={identificators.HEADER_GET_IN_TOUCH}
              className={clsx('btn btn_60 btn__white', styles.btn, styles.contact)}
              to={linkTo ? linkTo : '/get-in-touch/'}
            >
              {btnText}
            </Link>
            <a
              className="btnCall"
              href={`${contact.calendly_link}?utm_source=brocoders&utm_medium=header&utm_campaign=calendly_link&hide_gdpr_banner=1`}
            >
              {contact.link_name}
            </a>
          </div>
        )}
      </ServiceHeader>
      <main className={clsx('main', styles.main)}>{children}</main>
      <Footer />
    </Layout>
  );
}

export default Main;
